import React,
{
  useEffect,
  useRef,
  useState,
} from 'react';
// @ts-ignore
import { MdImage, MdFileDownload } from 'react-icons/md';
import toast from 'react-hot-toast';
import ImageUploading, { ImageListType } from 'react-images-uploading';
import BaseModal, { IBaseModalProps } from '../../BaseModal';
import { Activity } from '../../../store/Activities.store';
import { exportAsImage, formatDuration } from '../../../utils';
import {
  Container,
  MetricTitle,
  MetricValue,
  LinkButton,
  LinkButtonText,
  Thumbnail,
  LogoImage,
  MetricHeader,
  MetricsContainer,
  OptionsContainer,
  RadioButton,
  RadioButtonCheck,
  RadioButtonIcon,
  RadioText,
  ShareContainer,
  ChooseImageBtn,
} from './styles';
import Indoor from '../../../assets/sharing/indoor.jpg';
import Running from '../../../assets/sharing/running2.jpg';
import Bike from '../../../assets/sharing/bike.jpg';
import Logo from '../../../assets/sharing/cp_logo_img.png';
import CropModal from '../CropModal';
import Others from '../../../assets/sharing/Others.jpg';
import Weight from '../../../assets/sharing/weight.jpg';
import Drills from '../../../assets/sharing/Drills.jpg';
import { ILap } from '../AnalysisModal';
import api from '../../../services/api';

const { ClipboardItem } = window;

export interface SharingActivityModalProps extends IBaseModalProps {
  activity: Activity;
  mapImage?: string | null;
  laps?: ILap[];
}

function SharingActivityModal({
  activity, mapImage, laps, ...modalProps
}: SharingActivityModalProps) {
  const [selectedImage, setSelectedImage] = useState<string>('');
  const [defaultImage, setDefaultImage] = useState(Running);
  const [clipboardItem, setClipboardItem] = useState<any>();

  const [distance] = useState(true);
  const [pace, setPace] = useState(true);
  const [bestLap, setBestLap] = useState(false);
  const [time] = useState(true);
  const [none, setNone] = useState(false);
  const [altimetry, setAltimetry] = useState(false);

  const titleObj: { [key: string]: string } = {
    Run: 'Corrida',
    Ride: 'Bicicleta',
    Swim: 'Natação',
    WeightTraining: 'Treino de Força',
    Drills: 'Educativo',
    Walk: 'Caminhada',
    Workout: 'Treino',
    Others: 'Outros',
  };

  const best_lap = laps
    ? Math.min(...laps.map((lap: any) => (lap.moving_time / lap.distance))) : null;

  const isRunOrWalk = activity.type === 'Run' || activity.type === 'Walk';

  const viewShotRef = useRef(null);

  const saveImage = async () => {
    const viewShot = await exportAsImage(viewShotRef?.current as any);

    const fileName = 'CorridaPerfeita.jpg';
    const el = document.createElement('a');
    el.setAttribute('href', viewShot);
    el.setAttribute('download', fileName);
    document.body.appendChild(el);
    el.click();
    el.remove();
  };

  const getSticker = async () => {
    const url = `user_activities/sticker/${activity.id}`;
    const response = await api.get(url, {
      responseType: 'blob',
    });

    const blob = response.data;

    const reader = new FileReader();

    reader.onload = () => {
      const dataUrl = reader.result;
      generateClipboardItem(dataUrl as string);
    };
    reader.readAsDataURL(blob);
  };

  const generateClipboardItem = async (src: string) => {
    const covertedPngImage = new Image();
    covertedPngImage.src = src;
    covertedPngImage.onload = () => {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      canvas.width = covertedPngImage.width;
      canvas.height = covertedPngImage.height;
      ctx?.drawImage(covertedPngImage, 0, 0);
      canvas.toBlob((blob) => {
        if (blob) {
          const item = new ClipboardItem({ 'image/png': blob });
          setClipboardItem(item);
        }
      });
    };
  };

  const copyImageToClipboard = async () => {
    if (clipboardItem) {
      await navigator.clipboard.write([clipboardItem]);
      toast.success('Imagem copiada para o clipboard');
    }
  };

  useEffect(() => {
    getSticker();
  }, []);

  const setPaceOption = () => {
    if (bestLap) setBestLap(false);
    if (none) setNone(false);
    if (altimetry) setAltimetry(false);
    setPace(true);
  };

  const setNoneOption = () => {
    if (pace) setPace(false);
    if (bestLap) setBestLap(false);
    if (altimetry) setAltimetry(false);
    setNone(true);
  };

  const setBestLapOption = () => {
    if (pace) setPace(false);
    if (none) setNone(false);
    if (altimetry) setAltimetry(false);
    setBestLap(true);
  };

  const setAltimetryOption = () => {
    if (pace) setPace(false);
    if (bestLap) setBestLap(false);
    if (none) setNone(false);
    setAltimetry(true);
  };

  const defineDefaultImage = () => {
    if (activity.type === 'Run') setDefaultImage(Running);
    else if (activity.type === 'Walk') setDefaultImage(Indoor);
    else if (activity.type === 'Ride') setDefaultImage(Bike);
    else if (activity.type === 'WeightTraining') setDefaultImage(Weight);
    else if (activity.type === 'Drills') setDefaultImage(Drills);
    else setDefaultImage(Others);
  };

  useEffect(() => {
    defineDefaultImage();
  }, []);

  const [images] = React.useState([]);
  const [cropMode, setCropMode] = React.useState(false);

  const onChange = (imageList: ImageListType) => {
    setSelectedImage(imageList[0].dataURL || '');
    setCropMode(true);
    modalProps.setIsVisible?.(false);
  };

  return (
    <>
      <CropModal
        isVisible={cropMode}
        setIsVisible={setCropMode}
        acceptCallback={(url) => {
          setSelectedImage(url);
          setCropMode(false);
          modalProps.setIsVisible?.(true);
        }}
        image={selectedImage}
        closeCallback={() => {
          modalProps.setIsVisible?.(true);
          setCropMode(false);
          setSelectedImage('');
        }}
      />
      <BaseModal
        {...modalProps}
        title="Compartilhar atividade"
        closeCallback={() => {
          setSelectedImage('');
        }}
      >
        <Container>
          <Thumbnail
            ref={viewShotRef}
            imageUrl={
              selectedImage || (mapImage || defaultImage)
            }
          >
            <LogoImage src={Logo} />
            <MetricsContainer>
              {!isRunOrWalk && (
                <div>
                  <MetricTitle style={{ fontWeight: 'bold' }}>
                    {activity.type ? (titleObj[activity.type] || '') : '-'}
                  </MetricTitle>
                </div>
              )}
              {!isRunOrWalk && (!!activity.calorie) && (
                <div>
                  <MetricTitle>Calorias</MetricTitle>
                  <MetricValue>
                    {activity.calorie ? `${activity.calorie} cal` : '-'}
                  </MetricValue>
                </div>
              )}
              {isRunOrWalk && distance && (
                <div>
                  <MetricTitle>Distância</MetricTitle>
                  <MetricValue>
                    {activity.distance ? `${activity.distance.toFixed(2)} km` : '-'}
                  </MetricValue>
                </div>
              )}
              {isRunOrWalk && bestLap && (
                <div>
                  <MetricTitle>Melhor volta</MetricTitle>
                  <MetricValue>
                    {best_lap ? `${formatDuration(best_lap, 'pace')} /km` : '-'}
                  </MetricValue>
                </div>
              )}
              {isRunOrWalk && pace && (
                <div>
                  <MetricTitle>Ritmo médio</MetricTitle>
                  <MetricValue>
                    {activity.pace
                      ? `${formatDuration(activity.pace, 'pace')} /km`
                      : '-'}
                  </MetricValue>
                </div>
              )}
              {isRunOrWalk && altimetry && (
                <div>
                  <MetricTitle>Ganho de elevação</MetricTitle>
                  <MetricValue>
                    {activity.altimetry ? `${Math.trunc(activity.altimetry)} m` : '-'}
                  </MetricValue>
                </div>
              )}
              {time && (
                <div>
                  <MetricTitle>Tempo</MetricTitle>
                  <MetricValue>
                    {activity.duration
                      ? `${formatDuration(activity.duration as number, 'time')}`
                      : '--:--:--'}
                  </MetricValue>
                </div>
              )}
            </MetricsContainer>
          </Thumbnail>

          <OptionsContainer>
            <LinkButton>

              <ImageUploading
                value={images}
                onChange={onChange}
                maxNumber={10}
              >

                {({
                  onImageUpload,
                  dragProps,
                }) => (
                  <ChooseImageBtn
                    type="button"
                    onClick={onImageUpload}
                    {...dragProps}
                  >

                    <MdImage size={20} color="white" />

                    Escolha uma imagem
                  </ChooseImageBtn>
                )}

              </ImageUploading>
            </LinkButton>
            {isRunOrWalk && (
              <LinkButton onClick={() => copyImageToClipboard()}>
                <LinkButtonText>Sticker</LinkButtonText>
              </LinkButton>
            )}
            <LinkButton onClick={() => saveImage()}>
              <MdFileDownload size={20} color="white" />
              <LinkButtonText>Salvar</LinkButtonText>
            </LinkButton>

          </OptionsContainer>
          {isRunOrWalk
            && (
              <ShareContainer>
                <MetricHeader>Escolha a métrica de ritmo</MetricHeader>
                <RadioButton
                  selected={pace}
                  onClick={() => setPaceOption()}
                >
                  <RadioButtonIcon>
                    {pace && <RadioButtonCheck />}
                  </RadioButtonIcon>
                  <RadioText>Ritmo médio</RadioText>
                </RadioButton>
                <RadioButton
                  selected={bestLap}
                  onClick={() => setBestLapOption()}
                >
                  <RadioButtonIcon>
                    {bestLap && <RadioButtonCheck />}
                  </RadioButtonIcon>
                  <RadioText>Melhor volta</RadioText>
                </RadioButton>
                <RadioButton
                  selected={altimetry}
                  onClick={() => setAltimetryOption()}
                >
                  <RadioButtonIcon>
                    {altimetry && <RadioButtonCheck />}
                  </RadioButtonIcon>
                  <RadioText>Ganho de elevação</RadioText>
                </RadioButton>
                <RadioButton
                  selected={none}
                  onClick={() => setNoneOption()}
                >
                  <RadioButtonIcon>
                    {none && <RadioButtonCheck />}
                  </RadioButtonIcon>
                  <RadioText>Nenhuma</RadioText>
                </RadioButton>
              </ShareContainer>
            )}
        </Container>
      </BaseModal>
    </>

  );
}
export default SharingActivityModal;
