import React, { useEffect, useState } from 'react';
import { format } from 'date-fns';
import { useSelector } from 'react-redux';
import {
  Card,
  Text,
} from './style';
import Subscription from '../SubscriptionDetails';
import { RootState } from '../../store';
import RefundModal from '../RefundModal';
import CancellationModal from '../CancellationModal';
import RefundFormModal from '../RefundFormModal';
import PauseModal from '../PauseModal';

export default function SubscriptionInfo() {
  const subscription = useSelector((state: RootState) => state.subscription.subscription);
  const auth = useSelector((state: RootState) => state.auth);
  const { user } = auth;
  const [isRefundable, setIsRefundable] = useState(false);

  const [showRefundModal, setShowRefundModal] = useState(false);
  const [showCancellationModal, setShowCancellationModal] = useState(false);
  const [showRefundFormModal, setShowRefundFormModal] = useState(false);
  const [showPauseModal, setShowPauseModal] = useState(false);

  useEffect(() => {
    if (subscription?.start_at) {
      const dateStart = new Date(subscription.start_at);
      const dateNow = new Date();
      const diffTime = Math.abs(dateNow.getTime() - dateStart.getTime());
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      if (subscription?.status === 'active' && diffDays <= 15) {
        setIsRefundable(true);
      }
    }
  }, [subscription]);

  return (
    <>
      <Card data-testid="modal">
        <Subscription />

        {
          subscription?.plan
          && (
            <Text>
              <h5>ALTERAÇÕES E OUTROS</h5>

              <p>
                Caso desejar modificar algo, como trocar o plano, ou tratar de
                outro assunto sobre sua assinatura, escreva para
                assinatura@corridaperfeita.com.
              </p>

              {
                subscription.status === 'active'
                && (user.subscription_type === 'ESSENTIAL' || user.subscription_type === 'BASIC' || user.subscription_type === 'START')
                && (
                  <>
                    <h5>CANCELAR FUTURAS RENOVAÇÕES</h5>
                    <p>
                      Para solicitar o cancelamento da renovação/pagamento previsto
                      para
                      {' '}
                      {subscription ? format(new Date(subscription.next_billing_at), 'dd-MM-yyyy') : ''}
                      ,
                      &nbsp;
                      <a href="#assinatura" style={{ color: 'red' }} onClick={() => setShowCancellationModal(true)}>
                        Clique aqui.
                      </a>
                    </p>
                  </>
                )
              }

              {
                isRefundable
                && (user.subscription_type === 'ESSENTIAL' || user.subscription_type === 'START')
                && (
                  <>
                    <h5>PEDIDO DE REEMBOLSO</h5>
                    <p>
                      Para solicitar reembolso previsto no período de até 15 dias após o início de seu plano. &nbsp;
                      <a href="#assinatura" style={{ color: 'red' }} onClick={() => setShowRefundFormModal(true)}>
                        Clique aqui.
                      </a>
                    </p>
                  </>
                )
              }

              <RefundModal
                show={showRefundModal && !showPauseModal && !showRefundFormModal}
                toggle={() => setShowRefundModal(!showRefundModal)}
                setShowRefundFormModal={setShowRefundFormModal}
                setShowPauseModal={setShowPauseModal}
              />

              <RefundFormModal
                show={showRefundFormModal}
                toggle={() => setShowRefundFormModal(!showRefundFormModal)}
              />

              <PauseModal
                show={showPauseModal}
                toggle={() => setShowPauseModal(!showPauseModal)}
              />

              <CancellationModal
                show={showCancellationModal}
                toggle={() => setShowCancellationModal(!showCancellationModal)}
              />
            </Text>
          )
        }
      </Card>
    </>
  );
}
