import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import toast from 'react-hot-toast';
import { AppThunk } from '.';
import { getUrlQueryParams, getGarminToken } from '../utils';
import coreApi from '../services/coreApi';
import {
  CorosConfig, GarminConfig, PolarConfig, StravaConfig,
} from '../types/Integration';
import api from '../services/api';
import { refreshUserSuccess } from './Auth.store';

type InitialState = {
  coros_config: CorosConfig
  strava_config: StravaConfig
  garmin_config: GarminConfig
  loading: boolean
  polar_config: PolarConfig
}

const integration = createSlice({
  name: 'integration',
  initialState: {
    coros_config: {} as CorosConfig,
    strava_config: {} as StravaConfig,
    garmin_config: {} as GarminConfig,
    polar_config: {} as PolarConfig,
    loading: false,
  } as InitialState,
  reducers: {
    clearAllIntegrations: (state) => {
      state.coros_config = {} as CorosConfig;
      state.strava_config = {} as StravaConfig;
      state.garmin_config = {} as GarminConfig;
      state.polar_config = {} as PolarConfig;
    },
    setCorosConfig: (state, action: PayloadAction<CorosConfig>) => {
      state.coros_config = action.payload;
    },
    setStravaConfig: (state, action: PayloadAction<StravaConfig>) => {
      state.strava_config = action.payload;
    },
    setGarminConfig: (state, action: PayloadAction<GarminConfig>) => {
      state.garmin_config = action.payload;
    },
    removeCorosConfig: (state) => {
      state.coros_config = {} as CorosConfig;
    },
    removeStravaConfig: (state) => {
      state.strava_config = {} as StravaConfig;
    },
    removeGarminConfig: (state) => {
      state.garmin_config = {} as GarminConfig;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setPolarConfig: (state, action: PayloadAction<PolarConfig>) => {
      state.polar_config = action.payload;
    },
    removePolarConfig: (state) => {
      state.polar_config = {} as PolarConfig;
    },
  },
});

export const {
  clearAllIntegrations,
  setCorosConfig,
  setGarminConfig,
  setStravaConfig,
  removeCorosConfig,
  removeStravaConfig,
  removeGarminConfig,
  setLoading,
  setPolarConfig,
  removePolarConfig,
} = integration.actions;

export default integration.reducer;

export const integrateGarmin = (url: string): AppThunk => (
  async (dispatch) => {
    dispatch(setLoading(true));

    try {
      const token = await getGarminToken(url);

      const queryParams = getUrlQueryParams(token);
      const { oauth_token, oauth_token_secret } = queryParams;

      if (
        !oauth_token
        && !oauth_token_secret
      ) throw new Error('Params Error');

      const { data } = await coreApi.post('garmin/integrate', {
        access_token: oauth_token,
        token_secret: oauth_token_secret,
      });

      dispatch(setGarminConfig({ ...data, access_token: undefined, token_secret: undefined }));

      toast('Integração Garmin realizada com sucesso!');
    } catch (err) {
      toast.error('Erro ao integrar com a Garmin!');
    } finally {
      dispatch(setLoading(false));
    }
  }
);

export const getGarmin = (): AppThunk => (
  async (dispatch) => {
    try {
      const { data } = await coreApi.get('garmin/config');

      dispatch(setGarminConfig({ ...data, access_token: undefined, token_secret: undefined }));
    } catch (err: any) {
      if (err.response.data?.message === 'user_has_no_garmin_credentials') {
        dispatch(removeGarminConfig());
      }
    }
  }
);

export const removeGarmin = (): AppThunk => (
  async (dispatch, getState) => {
    dispatch(setLoading(true));

    let someVersionRemoved = false;

    const userId = getState().auth.user.id;

    try {
      const response = await api.put(`users/${userId}?removeGarminIntegration=true`, {
        garmin_access_token: '',
        garmin_token_secret: '',
      });

      dispatch(refreshUserSuccess({ user: response.data }));

      someVersionRemoved = true;
    } catch (err) {
      //
    }

    try {
      await coreApi.delete('garmin/config');

      dispatch(removeGarminConfig());

      someVersionRemoved = true;
    } catch (err) {
      //
    } finally {
      dispatch(setLoading(false));
    }

    if (someVersionRemoved) {
      toast('Integração com a Garmin removida.');
    } else {
      toast.error('Erro ao remover integração com a Garmin.');
    }
  }
);

export const integrateStrava = (url: string, provider_through: string | null = null): AppThunk => (
  async (dispatch) => {
    dispatch(setLoading(true));

    try {
      const queryParams = getUrlQueryParams(url);

      const { code } = queryParams;

      const { data } = await coreApi.post('strava/integrate', {
        code,
        provider_through,
      });

      dispatch(setStravaConfig({ ...data, access_token: undefined, refresh_token: undefined }));

      toast('Integração com a Strava realizada com sucesso!');
    } catch (err) {
      toast.error('Erro ao integrar com a Strava!');
    } finally {
      dispatch(setLoading(false));
    }
  }
);

export const getStrava = (): AppThunk => (
  async (dispatch) => {
    try {
      const { data } = await coreApi.get('strava/config');

      dispatch(setStravaConfig({ ...data, access_token: undefined, refresh_token: undefined }));
    } catch (err: any) {
      if (err.response.data?.message === 'user_has_no_strava_credentials') {
        dispatch(removeStravaConfig());
      }
    }
  }
);

export const removeStrava = (): AppThunk => (
  async (dispatch, getState) => {
    dispatch(setLoading(true));

    let someVersionRemoved = false;

    const userId = getState().auth.user.id;

    try {
      const response = await api.put(`users/${userId}?removeStravaIntegration=true`, {
        s_id: '',
        s_username: '',
        s_access_token: '',
        s_refresh_token: '',
        provider_through_strava: '',
      });

      dispatch(refreshUserSuccess({ user: response.data }));

      someVersionRemoved = true;
    } catch (err) {
      //
    }

    try {
      await coreApi.delete('strava/config');

      dispatch(removeStravaConfig());

      someVersionRemoved = true;
    } catch (err) {
      //
    } finally {
      dispatch(setLoading(false));
    }

    if (someVersionRemoved) {
      toast('Integração com a Strava removida.');
    } else {
      toast.error('Erro ao remover integração com a Strava.');
    }
  }
);

export const integrateCoros = (url: string): AppThunk => (
  async (dispatch) => {
    dispatch(setLoading(true));

    try {
      const queryParams = getUrlQueryParams(url);

      const { code } = queryParams;

      const { protocol, host } = window.location;

      const { data } = await coreApi.post('coros/integrate', {
        code,
        redirect_uri: `${protocol}//${host}/profile/edit?provider=coros`,
      });

      dispatch(setCorosConfig({ ...data, access_token: undefined, refresh_token: undefined }));

      toast('Integração com a Coros realizada com sucesso!');
    } catch (err) {
      toast.error('Erro ao integrar com a Coros!');
    } finally {
      dispatch(setLoading(false));
    }
  }
);

export const getCoros = (): AppThunk => (
  async (dispatch) => {
    try {
      const { data } = await coreApi.get('coros/config');

      dispatch(setCorosConfig({ ...data, access_token: undefined, refresh_token: undefined }));
    } catch (err: any) {
      if (err.response.data?.message === 'user_has_no_coros_credentials') {
        dispatch(removeCorosConfig());
      }
    }
  }
);

export const removeCoros = (): AppThunk => (
  async (dispatch) => {
    dispatch(setLoading(true));

    try {
      await coreApi.delete('coros/config');

      dispatch(removeCorosConfig());

      toast('Integração com a Coros removida.');
    } catch (err) {
      toast.error('Erro ao remover integração com a Coros.');
    } finally {
      dispatch(setLoading(false));
    }
  }
);

export const integratePolar = (url: string): AppThunk => (
  async (dispatch) => {
    dispatch(setLoading(true));

    try {
      const queryParams = getUrlQueryParams(url);

      const { code } = queryParams;

      const { protocol, host } = window.location;

      const { data } = await coreApi.post('polar/integrate', { code, redirect_uri: `${protocol}//${host}/profile/edit?provider=polar` });

      dispatch(setPolarConfig({ ...data, access_token: undefined, token_type: undefined }));

      toast('Integração com a Polar realizada com sucesso!');
    } catch (err) {
      toast.error('Erro ao integrar com a Polar!');
    } finally {
      dispatch(setLoading(false));
    }
  }
);

export const getPolar = (): AppThunk => (
  async (dispatch) => {
    try {
      const { data } = await coreApi.get('polar/config');

      dispatch(setPolarConfig({ ...data, access_token: undefined, token_type: undefined }));
    } catch (err: any) {
      if (err.response.data?.message === 'user_has_no_polar_credentials') {
        dispatch(removePolarConfig());
      }
    }
  }
);

export const removePolar = (): AppThunk => (
  async (dispatch) => {
    dispatch(setLoading(true));

    try {
      await coreApi.delete('polar/config');

      dispatch(removePolarConfig());

      toast('Integração com a Polar removida.');
    } catch (err) {
      toast.error('Erro ao remover integração com a Polar.');
    } finally {
      dispatch(setLoading(false));
    }
  }
);
